
.app {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #303030; /* Color oscuro tipo gris material */
  background-image: url('appBackground.png');
  background-size: contain; /* Ajusta la imagen para que cubra todo el contenedor */
  background-position: center; /* Centra la imagen */
  background-repeat: no-repeat; /* Evita que se repita la imagen */
}

/* Estilos para la barra superior */
.top-bar {
  display: flex;
  align-items: center;
  background-color: #222222;
  padding: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Sombra para el top bar */
}

/* Estilos para el logotipo */
.logo {
  width: 120px; /* Ancho del logotipo */
  height: auto;
}

/* Estilos para el cuerpo de la página */
.body {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Estilos para la tarjeta */
.card {
  background-color: #222222; /* Color de fondo más oscuro para la tarjeta */
  padding: 0;
  border-radius: 8px; /* Bordes redondeados */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.4); /* Sombra para la tarjeta */
  aspect-ratio: 0.8; /* Aspect ratio de la tarjeta */
  display: flex;
  orientation: vertical;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

/* Estilos para el contenido de la tarjeta */
.card-content {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 100px;
  flex-direction: column;
}

/* Estilos para el botón de conexión */
.connect-button {
  margin: 24px 0px;
  background-color: #4caf50; /* Color de fondo del botón */
  color: white; /* Color del texto del botón */
  padding: 12px 24px; /* Espaciado interno del botón */
  border: none;
  border-radius: 8px; /* Bordes redondeados */
  cursor: pointer;
  width: 200px;
}

/* Estilos para el botón de conexión */
.sign-button {
  margin: 24px 0px;
  background-color: #314e74; /* Color de fondo del botón */
  color: white; /* Color del texto del botón */
  padding: 12px 24px; /* Espaciado interno del botón */
  border: none;
  border-radius: 8px; /* Bordes redondeados */
  cursor: pointer;
  width: 200px;
}

.sign-complete {
  margin: 24px 0px;
  background-color: #4caf86; /* Color de fondo del botón */
  color: white; /* Color del texto del botón */
  padding: 12px 24px; /* Espaciado interno del botón */
  border: none;
  border-radius: 8px; /* Bordes redondeados */
  cursor: pointer;
  width: 200px;
}

.error-message {
  color: white;
  padding: 0 20px;
}

.whatalab-logo {
  max-width: 200px;
}

.spinner {
  animation: spin 1s linear infinite;
}


.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
}

.modal-content {
  background-color: #222222;
  padding: 16px;
  border-radius: 8px;
  text-align: center;
  color: white
}


.copy-button,
.connect-hashpack-button {
  margin-top: 16px;
  padding: 8px 16px;
  border-radius: 4px;
  background-color: #009688;
  color: #fff;
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
}

.close-button {
  margin-top: 16px;
  color: #009688;
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
}

.copy-button,
.connect-hashpack-button,
.close-button {
  margin-top: 16px;
  padding: 8px 16px;
  border-radius: 4px;
  background-color: #009688;
  color: #fff;
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
}

.close-button {
  background-color: rgba(0, 255, 255, 0);
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.mobile-text {
  margin-top: 24px;
}

.mobile-button {
  background-color: transparent;
  border: none;
  color: white;
  text-decoration: underline;
  cursor: pointer;
}
